import * as React from 'react';

import { cn } from '@cardo/lib';
import { useRef, useState } from 'react';
import * as reactUse from 'react-use';
const { useClickAway, useHover } = reactUse;

type TooltipProps = {
  children: React.ReactNode;
  content: React.ReactNode;
  position?: 'top' | 'left' | 'bottom';
  containerClassName?: string;
  childrenClassName?: string;
};

export function Tooltip({
  content,
  children,
  position = 'top',
  containerClassName,
  childrenClassName,
}: TooltipProps) {
  const [show, setShow] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useClickAway(ref, () => setShow(false));

  const element = () => (
    <div
      className={cn(
        'cursor-help',
        childrenClassName,
        !childrenClassName?.includes('p-') && 'p-2',
      )}
    >
      {children}
    </div>
  );

  const [hoverable, hovered] = useHover(element);

  return (
    <div
      className={cn(containerClassName, 'sm:relative')}
      ref={ref}
      onClick={() => setShow((prev) => !prev)}
    >
      {hoverable}
      {(hovered || show) && (
        <div
          className={cn(
            'absolute mx-auto w-[70vw] left-1/2 -translate-x-1/2 sm:translate-x-0 sm:w-max sm:max-w-[300px] bg-gray-900 text-white rounded p-2 z-50',
            {
              'sm:bottom-full sm:left-full': position === 'top',
              'sm:bottom-1/2 sm:left-auto sm:right-full': position === 'left',
              'sm:top-full sm:left-auto sm:right-full': position === 'bottom',
            },
          )}
        >
          {content}
        </div>
      )}
    </div>
  );
}
